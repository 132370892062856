import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesTalents: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Talents - Season 2</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_talents.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Talents - Season 2</h1>
          <h2>
            An explanation for the Season 2 Talents system in AFK Journey.
          </h2>
          <p>
            Last updated: <strong>18/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Talents - Season 2" />
        <StaticImage
          src="../../../images/afk/generic/talents_2.webp"
          alt="Guides"
        />
        <p>
          Faction Talents are special buffs replacing Faction Bonuses in certain
          game modes. They provide stat boosts to all heroes and create Talent
          Buff Tiles, turning placed heroes into Prime Carriers with additional
          skills.{' '}
          <strong>
            At least 3 heroes from the same faction are needed to activate the
            Talent bonus
          </strong>
          , but Celestial and Hypogeans heroes don't count as they have their
          own Faction Talent tree - so you're not able to bypass the
          requirements like with the normal Faction bonuses.
        </p>
        <h5>How to upgrade Talents?</h5>
        <p>
          As you advance through your Season Resonance Levels and AFK Stages,
          you earn <strong>Talent Orbs and Talent Essences</strong>. The first
          two upgrades of each Faction Talent are free - then Stage 1 and 2
          require Talent Orbs, and Stage 3 and 4 need Talent Essences.
        </p>
        <p>
          To unlock the next Stage, you must accumulatively activate a set
          number of Talents from all faction groups - this means that you can't
          just hyper-invest into a single faction and need to split your focus
          between them.
        </p>
        <h5>Are the Talents active everywhere?</h5>
        <p>No. The new Talent system can be used only in those 3 modes:</p>
        <ul>
          <li>Talent Challenge AFK Stages,</li>
          <li>Dura Trials,</li>
          <li>Battle Drill.</li>
        </ul>
        <p>
          So Arena and Dream Realm still use the old talent system, not the new
          one.
        </p>
        <h5>Can I change the Prime Carrier slot tile?</h5>
        <StaticImage
          src="../../../images/afk/generic/talents_1.webp"
          alt="Guides"
        />
        <p>
          No. The Prime Carrier slot always appears in the backline (check
          picture above).
        </p>
        <h5>So only the Prime Carrier benefits from the tree buffs?</h5>
        <p>
          All team members benefit from the 'smaller' nodes bonuses that give
          passive stats like Haste or ASPD, but only the Prime Carrier can make
          use of the 'big' talent.
        </p>
        <p>
          Also, at later Stages, you will unlock a secondary Prime Carrier slot,
          allowing two characters to benefit from the 'big' talent.
        </p>
        <h5>Can I reset the tree if I make an mistake?</h5>
        <p>
          Yes! You can reset Faction Talents for free every 7 days or use a
          Talent Reshaper and it will refund all of your spent Talent Orbs and
          Talent Essences used.
        </p>
        <SectionHeader title="Big Faction Talents" />
        <div className="afk-factions-table talents">
          <div className="faction-div Lightbearer">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_light.png"
                alt="Lightbearers"
                className="faction-icon"
              />{' '}
              Lightbearers
            </h5>
            <div className="info">
              <p>
                <strong>Big Talent (Echoing Radiance):</strong> When the prime
                carrier casts the Ultimate, they summon a shadow that casts the
                Ultimate again. The shadow inherits 60% of the prime carrier's
                ATK and 100% of their other stats, along with an extra 12 points
                of Penetration.
              </p>
              <p>Upgrades:</p>
              <ul>
                <li>
                  <strong>Stage 1:</strong> Increases the Ultimate damage for
                  the prime carrier and their shadow by 20%.
                </li>
                <li>
                  <strong>Stage 2:</strong> Recovers 100 Energy each time the
                  prime carrier assists or defeats an enemy.
                </li>
                <li>
                  <strong>Stage 3:</strong> When the prime carrier is defeated,
                  a shadow remains and inherits the prime carrier's Energy. The
                  shadow gains 100 Energy per second and will automatically cast
                  the Ultimate when its Energy is full before disappearing from
                  the battlefield.
                </li>
              </ul>
            </div>
          </div>
          <div className="faction-div Mauler">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_mauler.png"
                alt="Mauler"
                className="faction-icon"
              />{' '}
              Maulers
            </h5>
            <div className="info">
              <p>
                <strong>Big Talent (Bloodrage Step):</strong> The prime carrier
                will teleport to the nearest area with the most enemies when a
                battle starts, gaining a shield equal to 45% of max HP and
                immediately deal 170% ATK damage to all adjacent enemies. They
                then continue to deal the same damage every 5s to adjacent
                enemies.
              </p>
              <p>Upgrades:</p>
              <ul>
                <li>
                  <strong>Stage 1</strong>: Grants an extra 30 Life Drain to
                  this damage.
                </li>
                <li>
                  <strong>Stage 2</strong>: The prime carrier is unaffected
                  during the first 8s of a battle.
                </li>
                <li>
                  <strong>Stage 3</strong>: The prime carrier is immune to the
                  first fatal blow they take. They are then immune to all damage
                  and become unaffected for 5s
                </li>
              </ul>
            </div>
          </div>
          <div className="faction-div Wilder">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_wilder.png"
                alt="Wilder"
                className="faction-icon"
              />{' '}
              Wilders
            </h5>
            <div className="info">
              <p>
                <strong>Big Talent (Eternal Bloom):</strong> The prime carrier
                loses 50% of their max HP and gains a permanent shield that can
                block up to 55% HP damage when a battle starts. The prime
                carrier becomes unaffected while the shield is active and gains
                65 ATK SPD. If the Prime Carrier uses ranged attacks, their
                attack range will be extended to cover the entire battlefield,
                and the range of certain skills will expand accordingly.
              </p>
              <p>Upgrades:</p>
              <ul>
                <li>
                  <strong>Stage 1</strong>: Increases the prime carrier's normal
                  attack damage to enemies more than 3 tiles away by 20%
                </li>
                <li>
                  <strong>Stage 2</strong>: Increases the prime carrier's ATK
                  SPD by 3 every 3s, up to 30.
                </li>
                <li>
                  <strong>Stage 3</strong>: Allows the prime carrier's normal
                  attack to fire an extra projectile at the weakest enemy while
                  the shield is active dealing 55% ATK damage.
                </li>
              </ul>
            </div>
          </div>
          <div className="faction-div Graveborn">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_grave.png"
                alt="Graveborn"
                className="faction-icon"
              />{' '}
              Graveborn
            </h5>
            <div className="info">
              <p>
                <strong>Big Talent (Immortal Pact):</strong> The prime carrier
                selects another rearmost ally as a sacrifice when a battle
                starts. The sacrifice loses up to 5% of their current HP every
                second and recovers 100% of their lost HP for the prime carrier.
                This skill also increases the prime carrier's ATK, Phys & Magic
                DEF by 16% of the current sacrifice's corresponding stats.
              </p>
              <p>Upgrades:</p>
              <ul>
                <li>
                  <strong>Stage 1</strong>: Recovers 80% ATK HP for the prime
                  carrier when they assist or defeat a non-summoned enemy and
                  permanently increases their ATK by 10% and Phys & Magic DEF by
                  10%.
                </li>
                <li>
                  <strong>Stage 2</strong>: 80% of the sacrifice's lost HP per
                  second will be converted into a shield for the prime carrier.
                </li>
                <li>
                  <strong>Stage 3</strong>: Marks the farthest enemy as
                  sacrifice for 15s when a battle starts. During this time, the
                  sacrifice loses HP each second, up to 60% of the prime
                  carrier's ATK. If the sacrifice is defeated, the healthiest
                  remaining enemy becomes the new sacrifice for the rest of the
                  mark duration
                </li>
              </ul>
            </div>
          </div>
          <div className="faction-div Celestial">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_cele.png"
                alt="Celestial"
                className="faction-icon"
              />{' '}
              Celestials +&nbsp;{' '}
              <StaticImage
                src="../../../images/afk/icons/faction_hypo.png"
                alt="Hypogean"
                className="faction-icon"
              />{' '}
              Hypogeans
            </h5>
            <div className="info">
              <p>
                <strong>Big Talent (Dusklight Fusion):</strong> 6s into a battle
                and 6s thereafter, the prime carrier deals 120% ATK true damage
                to the area with the most adjacent enemies and stuns affected
                enemies for 1.5s. The prime carrier continues to cast this skill
                as long as they remain in the battle.
              </p>
              <p>Upgrades:</p>
              <ul>
                <li>
                  <strong>Stage 1</strong>: Recovers 40% ATK HP for all allied
                  prime carriers for every enemy hit.
                </li>
                <li>
                  <strong>Stage 2</strong>: Recovers 16 Energy for all allies
                  prime carriers for every enemy hit.
                </li>
                <li>
                  <strong>Stage 3</strong>: If both prime carriers remain in the
                  battle, the 'light' and 'dark' spells activate simultaneously
                  every 6s, starting 3s into the battle. The effect radius of
                  both spells extends to 2 tiles.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <SectionHeader title="What is the best Talent tree to invest into?" />
        <p>
          It's kinda hard to pick the best one since you need to use 3
          characters from a Faction to activate the special Talent in the first
          case and also everyone's account is different - so for example, those
          who invested heavily into Wilders will be able to make use of the
          Wilder tree easily compared to someone who invested into Maulers.
        </p>
        <p>
          Still, each Faction tree 'pushes' a different playstyle based on the
          big and small talents and here's a rough summary:
        </p>
        <ul>
          <li>
            <AFKItem name="Lightbearers" /> - favors mage-oriented teams as
            their ultimate is usually their most powerful skill and the Talent
            tree not only gives them a lot Haste, but also the big Talent allows
            them to 'use' the ultimate more often,
          </li>
          <li>
            <AFKItem name="Maulers" /> - favors melee-oriented teams (or so
            called Dive teams) that aim to disrupt the enemy formation and
            mainly consist of short-range characters,
          </li>
          <li>
            <AFKItem name="Wilders" /> - favors ranged-oriented teams (or rather
            they want the main carry to be a Ranger) as the Prime Carrier will
            not only gain a lot ASPD, but also gain battlefield-wide range,
          </li>
          <li>
            <AFKItem name="Graveborn" /> - a pretty generic tree that can work
            with any of the above playstyles as it focuses on buffing the damage
            and survivability of the Prime Carrier,
          </li>
          <li>
            <AFKItem name="Celestial" /> & <AFKItem name="Hypogean" /> - the
            strongest tree that is also very generic. It provides additional
            damage and CC and this allows it to work with any playstyle.
          </li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesTalents;

export const Head: React.FC = () => (
  <Seo
    title="Talents - Season 2 | AFK Journey | Prydwen Institute"
    description="An explanation for the Season 2 Talents system in AFK Journey."
    game="afk"
  />
);
